import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {APP_CONFIG, LoaderService, SocketService, UploadImageService, UtilService} from "@kwot/app-config";
import {AuthState, getLoggedInUser} from "@kwot/auth-store";
import {VendorBuyerState} from "../../+state/vendor-buyer.reducer";
import {
  GetNegotiationData,
  ResetVendorBuyerState, StartNegotiation, GetVendorAllProducts, OrderOfferLetter, GetRFQTermSheet
} from "../../+state/vendor-buyer.actions";
import {getNegotiationDetails, getProducts, getVendorBuyerSuccess} from "../../+state/vendor-buyer.selectors";
import * as dayjs from "dayjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AddToCartItems, BuyerState, GetCartItem} from "@kwot/buyer-store";
import {VendorState} from "../../../../../vendor-shared/src/lib/+state/vendor.reducer";
import {getCurrencyData, getVendorSuccess} from "../../../../../vendor-shared/src/lib/+state/vendor.selectors";
import {GetCurrencyData, UpdateProposal} from "../../../../../vendor-shared/src/lib/+state/vendor.actions";
import {CustomModalComponent} from "../../../../../shared/src/lib/components/custom-modal/custom-modal.component";

@Component({
  selector: 'kwot-rfq-negotiation',
  templateUrl: './rfq-negotiation.component.html',
  styleUrls: ['./rfq-negotiation.component.scss'],
})
export class RfqNegotiationComponent implements OnInit, OnDestroy {

  negotiationId: string;
  currentUser: any;
  unsubscriber = new Subject();
  productDetail: any;
  negotiationDetails: any
  selectedVendor: any;
  selectedProposal: any;
  _object = Object;

  chatPage = 0;
  hasMore = false;
  selectedRoom: any;
  chatMessages: any = {};
  chatMessage: any = '';
  public socketSubscription: any;
  modalRef: any;
  rfqForm: UntypedFormGroup;
  currencySymbol = '$';
  products: any = [];
  cartItems: any;
  currencyData: any;
  fields = [
    {name: 'primaryColour', value: 'Primary Colour'},
    {name: 'primaryMaterial', value: 'Primary Material'},
    {name: 'secondaryColour', value: 'Secondary Colour'},
    {name: 'secondaryMaterial', value: 'Secondary Material'}
  ];
  variations: any[] = [];
  isExpired: boolean = false;
  tabType: string = 'all';
  publicRfqDetails: any;
  paymentTerms: any[] = [
    {name: "KWOT Escrow", value: "kwot"},
    {name: "Advanced Payment", value: "advance_payment"},
    {name: "Letter of Credit", value: "loc"},
    {name: "Payment after 30 Days", value: "after_30_days"},
    {name: "Open Account Terms", value: "open_account_terms"},
    {name: "Documentary Collection", value: "documentary_collection"},
    {name: "Consignment", value: "consignment"},
  ];
  shippingTerms: any[] = [
    {name: "Cost and Freight (CFR)", value: "cfr"},
    {name: "Cost, Insurance and Freight (CIF)", value: "cif"},
    {name: "Free on Board (FOB)", value: "fob"}
  ];
  proposalList: any[] = [];
  isAcceptProposal: boolean = false;

  @ViewChild('sectionSubscribe', {static: true}) sectionSubscribeDiv: ElementRef;
  documentFile: any;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    private activeRoute: ActivatedRoute,
    private authStore: Store<AuthState>,
    private vendorBuyerStore: Store<VendorBuyerState>,
    private vendorStore: Store<VendorState>,
    private uploadImage: UploadImageService,
    private socketService: SocketService,
    private modelService: BsModalService,
    private toastr: ToastrService,
    private translationService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private buyerStore: Store<BuyerState>,
    private router: Router,
    private utilService: UtilService,
    private loaderService: LoaderService
  ) {
    this.vendorBuyerStore.dispatch(ResetVendorBuyerState({
      params: {
        error: '', success: '', negotiationDetails: null
      }
    }))
    this.negotiationId = this.activeRoute.snapshot.params['id'];
    this.vendorBuyerStore.dispatch(GetNegotiationData({params: {
        id: this.negotiationId,
        isVendor: this.appConfig.type === 'vendor'
      }}));
    this.createForm();
    this.subscribeToStore();
    this.subscribeToSocket();
  }

  subscribeToStore() {
    this.authStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((currentUser) => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      });
    this.authStore
      .pipe(select(getNegotiationDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((negotiationDetails) => {
        if (negotiationDetails) {
          this.negotiationDetails = {...negotiationDetails};

          if (this.negotiationDetails.negotiationOfferType === 'public') {
            this.publicRfqDetails = {
              productName: this.negotiationDetails?.productName,
              categoryName: this.negotiationDetails?.category[0]?.name,
              description: this.negotiationDetails?.message,
              shippingTerms: this.shippingTerms.find(item => item.value === this.negotiationDetails?.shippingTerms)?.name,
              paymentTerms: this.paymentTerms.find(item => item.value === this.negotiationDetails?.paymentTerms)?.name
            }
          }

          this.proposalList = this.negotiationDetails?.proposals.filter((item: any) => !item.isWithdraw);

          this.groupProposalsByVendor();
          if (this.negotiationDetails.negotiationOfferType === 'product') {
            let vendorProposals = this.negotiationDetails.proposals[Object.keys(this.negotiationDetails.proposals)[0]];
            let item = vendorProposals[vendorProposals.length - 1];
            this.selectProposal(item.vendorId._id);
            this.rfqForm.patchValue({
              productId: item.productId._id,
              price: item.price,
              qty: item.quantity
            })
          } else {
            if (this.appConfig.type === 'vendor' || (this.appConfig.type === 'user' && this.isExpired)) {
              const vendorId = this.appConfig.type === 'vendor' ? this.currentUser._id : this.selectedVendor;
              this.selectProposal(vendorId);
            }
          }
        }
      });
    this.authStore
      .pipe(select(getProducts))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((products) => {
        if (products) {
          this.products = products.data.map((t: any) => ({
            name: t.productName,
            id: t._id,
            currency: t.currency
          }))
        }
      });

    if (this.appConfig.type === 'user') {
      this.buyerStore
        .pipe(select(GetCartItem))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe((items) => {
          if (items) {
            this.cartItems = items;
          }
        });
    }

    if (this.appConfig.type === 'vendor') {
      this.vendorStore.pipe(select(getCurrencyData))
        .subscribe(currencyData => {
          if (currencyData) {
            this.currencyData = currencyData;
          }
        })

      this.vendorStore.pipe(select(getVendorSuccess))
        .subscribe(success => {
          if (success && success === '---IGNORE---') {
            this.toastr.success('Withdraw offer successfully.');
            this.changeTab(this.tabType)
          }
        })
    }

    this.vendorBuyerStore.pipe(select(getVendorBuyerSuccess))
      .subscribe(success => {
        if (success && success === '---IGNORE---') {
          if (this.isAcceptProposal) {
            this.isAcceptProposal = false;
            const tabType: string = this.appConfig.type === 'user' && this.tabType;
            this.getNegotiation(tabType);
            if (this.selectedVendor) {
              this.selectProposal(this.selectedVendor)
            }
          }
        }
      })
  }

  selectProposal(vendorId: any) {
    this.selectedVendor = vendorId;
    this.selectedProposal = null;
    if (this.negotiationDetails.proposals[vendorId]) {
      this.selectedProposal = this.negotiationDetails.proposals[vendorId][this.negotiationDetails.proposals[vendorId].length - 1];
    }
    if (this.selectedProposal && this.selectedProposal.status === 'approved by admin' &&  this.selectedProposal.status !== 'expired') {
      // counting business day
      let startDate = dayjs(this.selectedProposal.updatedAt);
      let endDate = dayjs(startDate).add(5, 'days');
      let numWorkDays = 0;
      if (startDate.format('MM/YYYY') <= endDate.format('MM/YYYY ')){
        while (startDate.isBefore(endDate, 'day')) {
          // Skips Sunday and Saturday
          numWorkDays++;
          if (startDate.day() == 0 || startDate.day() == 6) {
            numWorkDays++;
          }
          if (startDate.isBefore(endDate, 'minute')) {
            if(startDate.day() == 6) {
              numWorkDays++;
            }
          }
          startDate = dayjs(startDate).add(1, 'days');
        }
        let newDate = dayjs(this.selectedProposal.updatedAt).add(numWorkDays - 1, 'days');
        if (dayjs().isAfter(newDate, 'minute')) {
          const body: any = {
            negotiation_id: this.negotiationDetails._id,
            proposal_id: this.selectedProposal._id,
            expiredRfq: true,
            isVendor: this.appConfig.type === 'vendor',
          }
          this.isExpired = true;
          this.vendorBuyerStore.dispatch(StartNegotiation({body}));
        }
      }
    }

    this.productDetail = this.selectedProposal?.productId;
    // add product selectedOptions
    if (this.productDetail) {
      this.productDetail = {
        ...this.productDetail,
        selectedOptions: this.selectedProposal?.selectedOptions
      }
    }

    this.chatMessages = {};
    this.chatPage = 0;
    const message = {
      action: "INITIAL_CHAT_MESSAGES",
      payload: {
        userId: this.negotiationDetails.user._id,
        personId: vendorId,
        negotiationId: this.negotiationDetails._id,
        status: 'seen',
        pagination: {
          page: this.chatPage,
          limit: 20
        }
      },
    };
    this.socketService.sendMessage(message);
  }

  onScrollUp() {
    setTimeout(() => {
      this.chatPage += 1;
      if (this.hasMore) {
        const message = {
          action: "CHAT_MESSAGES",
          payload: {
            room: this.selectedRoom?._id,
            userId: this.currentUser._id,
            status: 'seen',
            pagination: {
              page: this.chatPage,
              limit: 20
            }
          },
        };
        this.socketService.sendMessage(message)
      }
    }, 1000)
  }

  groupProposalsByVendor() {
    let byVendors: any = {};
    if (this.negotiationDetails.proposals) {
      if (!Array.isArray(this.negotiationDetails.proposals)) {
        this.negotiationDetails.proposals = [this.negotiationDetails.proposals];
      }
      this.negotiationDetails.proposals.forEach((item: any) => {
        if (!byVendors[item.vendorId._id]) {
          byVendors[item.vendorId._id] = [];
        }
        byVendors[item.vendorId._id].push(item);
      })
    }
    this.negotiationDetails.proposals = byVendors
  }

  getName(item: any, status: any, type: string) {
    const buyerName = item?.user?.firstName + ' ' + item?.user?.lastName;
    const vendorName = item?.vendorId?.name;
    if (type === 'vendor') {
      return vendorName + (status === 'approved by vendor' ? ' accepted RFQ Price' : ' submitted a proposal');
    }
    if (type === 'user') {
      return buyerName + (status === 'approved by buyer' ? ' accepted RFQ Price' : ' Requested Quotation');
    }
    return 'Admin' + (status === 'approved by admin' ? ' accepted' : (status === 're-opened' ? ' re-opened' : ' updated')) + ' RFQ Price'
  }

  getImage(item: any, size: any) {
    if (item.type && item.type === 'digital') {
      return this.uploadImage.getImages(item.design, size);
    }
    return this.uploadImage.getImages(item.coverImage, size);
  }

  createForm() {
    this.rfqForm = this.formBuilder.group({
      productId: [null, Validators.required],
      price: ['', Validators.required],
      qty: ['', Validators.required],
      message: ['', Validators.required],
      shippingCost: ['', Validators.required],
      document: ['']
    });
  }

  ngOnInit(): void {
    if (this.appConfig.type === 'vendor') {
      this.vendorStore.dispatch(GetCurrencyData());
    }
  }

  downloadLetter() {
    let proposal: any = this.negotiationDetails.proposals[this.selectedVendor][this.negotiationDetails.proposals[this.selectedVendor].length - 1];
    this.vendorBuyerStore.dispatch(OrderOfferLetter({
      orderId: this.negotiationId,
      params: {type: this.appConfig.type, proposalId: proposal._id}
    }));
  }

  addToCart() {
    if ((this.cartItems && this.cartItems.items || []).length > 0) {
      this.toastr.warning('You can only place one order at a time.');
      return;
    }
    let cartData: any = (this.cartItems && this.cartItems.items || []).filter((data: any) => data._id === item._id)
    cartData = Object.assign({}, ...cartData)
    let item: any = this.negotiationDetails.proposals[this.selectedVendor][this.negotiationDetails.proposals[this.selectedVendor].length - 1];
    if (item.productId.qtyAvailable !== cartData.quantity) {
      let body: any = {
        productId: item.productId._id,
          variantId: item.productId.selectedVariation || null,
          quantity: item?.quantity,
          type: 'physical',
          ...(item.selectedOptions ? {selectedOptions: item.selectedOptions } : {}),
          selectedPrice: item.price,
          price: item.price,
          shippingCost: item.shippingCost,
          takeCustomMeasurements: item.productId.sizeMe?.isEnabled,
          shippingId: this.negotiationDetails.shippingId._id,
          paymentTerms: this.negotiationDetails.paymentTerms,
          proposalId: item._id
      }
      this.buyerStore.dispatch(AddToCartItems({ body }));
      this.toastr.success('Your product has been added to cart successfully!');
      this.router.navigate(['/user', 'cart']);
    } else {
      this.toastr.warning(this.translationService.instant('stock_limit_reached'))
    }
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.action === 'INITIAL_CHAT_MESSAGES') {
          this.selectedRoom = message.data;
        }
        if (message?.action === 'CHAT_MESSAGES') {
          let messageList = []
          this.hasMore = message.data.length >= 20;
          if (message.data.length > 0) {
            messageList = message.data.map((item: any) => ({
              msgId: item.id,
              id: item.sender._id ? item.sender._id : item.receiver._id,
              data: item.sender ? item.sender : item.receiver,
              message: item.message,
              negotiationType: item?.negotiationType,
              proposalMessage: item?.proposalMessage,
              status: item.status,
              date: item.createdAt
            }))
            messageList?.forEach((item: any) => {
              let formattedDate;
              if (dayjs(item.date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY')) {
                formattedDate = 'Today';
              } else if (dayjs(item.date).format('DD/MM/YYYY') === (dayjs().subtract(1, 'day').format('DD/MM/YYYY'))) {
                formattedDate = 'Yesterday';
              } else if (dayjs().startOf('week').format('DD/MM/YYYY') <= dayjs(item.date).format('DD/MM/YYYY') && dayjs().format('DD/MM/YYYY') >= dayjs(item.date).format('DD/MM/YYYY')) {
                formattedDate = dayjs(item.date).format('dddd');
              } else {
                formattedDate = dayjs(item.date).format('DD/MM/YYYY');
              }
              if (this.selectedRoom && !this.chatMessages[this.selectedRoom._id]) {
                this.chatMessages[this.selectedRoom._id] = {};
              }
              if (!this.chatMessages[this.selectedRoom._id][formattedDate]) {
                this.chatMessages[this.selectedRoom._id][formattedDate] = [];
              }
              if (this.chatPage === 0) {
                this.chatMessages[this.selectedRoom._id][formattedDate].push(item);
              } else {
                this.chatMessages[this.selectedRoom._id][formattedDate].unshift(item);
              }
            })
            if (this.chatPage === 0) {
              setTimeout(() => {
                this.sectionSubscribeDiv.nativeElement.scrollTop = this.sectionSubscribeDiv.nativeElement.scrollHeight;
              }, 500);
            }
            const updateMessage = {
              action: "MARK_AS_SEEN",
              payload: {
                room: this.selectedRoom?._id,
                userId: this.currentUser._id,
                status: 'seen'
              }
            };
            this.socketService.sendMessage(updateMessage)
          }
        }

        if (message?.action === 'NEW_MESSAGE') {
          // this.getRoomChats();
          if (message?.data?.room === this.selectedRoom?._id) {
            if (message?.data?.message) {
              if (!this.chatMessages[this.selectedRoom?._id]['Today']) {
                this.chatMessages[this.selectedRoom?._id]['Today'] = [];
              }
              this.chatMessages[this.selectedRoom?._id]['Today'].push({
                msgId: message.data.id,
                id: message.data.sender,
                data: (message.data.sender === this.selectedRoom.userId._id) ? this.selectedRoom.userId : this.selectedRoom.personId,
                message: message.data.message,
                negotiationType: message.data?.negotiationType,
                proposalMessage: message.data?.proposalMessage,
                status: message.data.status
              });
            }
            const updateMessage = {
              action: "MARK_AS_SEEN",
              payload: {
                room: message?.data?.room,
                userId: this.currentUser._id,
                status: 'seen'
              }
            };
            this.socketService.sendMessage(updateMessage)
            setTimeout(() => {
              this.sectionSubscribeDiv.nativeElement.scrollTop = this.sectionSubscribeDiv.nativeElement.scrollHeight;
            }, 500);
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  submit() {
    if (!this.chatMessage.trim()) {
      return;
    }
    const message = {
      action: "NEW_MESSAGE",
      payload: {
        room: this.selectedRoom._id,
        sender: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId._id : this.selectedRoom.userId._id,
        receiver: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.userId._id : this.selectedRoom.personId._id,
        message: this.chatMessage,
        status: 'sent'
      }
    };
    this.socketService.sendMessage(message);
    if (!this.chatMessages[this.selectedRoom?._id]) {
      this.chatMessages[this.selectedRoom?._id] = [];
    }
    if (!this.chatMessages[this.selectedRoom?._id]['Today']) {
      this.chatMessages[this.selectedRoom?._id]['Today'] = [];
    }
    this.chatMessages[this.selectedRoom?._id]['Today'].push({
      id: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId._id : this.selectedRoom.userId._id,
      data: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId : this.selectedRoom.userId,
      message: this.chatMessage,
      status: 'sent'
    });
    setTimeout(() => {
      this.sectionSubscribeDiv.nativeElement.scrollTop = this.sectionSubscribeDiv.nativeElement.scrollHeight;
    }, 500);
    this.chatMessage = '';
  }

  sendMessage(event: any, input: any) {
    if (event.keyCode === 13) {
      this.submit();
    } else {
      input.style.height = `${input.scrollHeight}px`;
    }
  }

  acceptRfq(type?: string) {
    let chatMessage = '';
    let proposal: any = this.negotiationDetails.proposals[this.selectedVendor][this.negotiationDetails.proposals[this.selectedVendor].length - 1];
    let body: any = {
      negotiation_id: this.negotiationDetails._id,
      proposal_id: proposal._id,
      acceptRfq: true,
      isVendor: this.appConfig.type === 'vendor'
    }
    if (this.appConfig.type === 'vendor') {
      body.isApproveByVendor = true;
      body.approvedByVendor = this.currentUser._id
      chatMessage = this.currentUser.name + ' has accepted the proposal'
      this.toastr.success(this.translationService.instant('accept_rfq_success'))
    }
    if (this.appConfig.type === 'admin') {
      if (type === 'reOpenRfq') {
        body.reOpenRfq = true;
        // chatMessage = this.currentUser.name ? this.currentUser.name : 'Admin' + ' has Re-opened the Proposal'
      } else {
        body.isApproveByAdmin = true;
        body.adminId = this.currentUser._id;
        // chatMessage = this.currentUser.name ? this.currentUser.name : 'Admin' + ' has approved your proposal'
        this.toastr.success(this.translationService.instant('approved_rfq_success'))
      }
    }
    if (this.appConfig.type === 'user') {
      body.isApproveByBuyer = true;
      chatMessage = this.currentUser.name ? this.currentUser.name : (this.currentUser.firstName + ' ' + this.currentUser.lastName) + ' has accepted your proposal'
      this.toastr.success('RFQ Price has been accepted')
    }
    this.vendorBuyerStore.dispatch(StartNegotiation({body}));
    this.isAcceptProposal = true;

    if (this.appConfig.type !== 'admin') {
      const message = {
        action: "NEW_MESSAGE",
        payload: {
          room: this.selectedRoom._id,
          sender: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId._id : this.selectedRoom.userId._id,
          receiver: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.userId._id : this.selectedRoom.personId._id,
          message: chatMessage,
          status: 'sent'
        }
      };
      this.socketService.sendMessage(message);
      if (!this.chatMessages[this.selectedRoom?._id]) {
        this.chatMessages[this.selectedRoom?._id] = [];
      }
      if (!this.chatMessages[this.selectedRoom?._id]['Today']) {
        this.chatMessages[this.selectedRoom?._id]['Today'] = [];
      }
      this.chatMessages[this.selectedRoom?._id]['Today'].push({
        id: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId._id : this.selectedRoom.userId._id,
        data: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId : this.selectedRoom.userId,
        message: chatMessage,
        status: 'sent'
      });
      setTimeout(() => {
        this.sectionSubscribeDiv.nativeElement.scrollTop = this.sectionSubscribeDiv.nativeElement.scrollHeight;
      }, 500);
      this.chatMessage = '';
    }
  }

  openModel(template: any) {
    this.vendorBuyerStore.dispatch(GetVendorAllProducts({
      params: {page: 0, limit: 500, criteria: 'active'}
    }))
    this.rfqForm.patchValue({
      qty: this.negotiationDetails.quantity
    })
    this.modalRef = this.modelService.show(template, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model shipping-cost-model'
    })
  }

  get form() {
    return this.rfqForm.controls;
  }

  reSendOffer() {
    let product = this.products.find((item: any) => item.id.toString() === this.rfqForm.value.productId)
    let fromCurrency = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRateFrom(this.currencyData, product.currency || 'USD');
    let toCurrency = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRate(this.currencyData);

    let body: any = {
      negotiation_id: this.negotiationDetails?._id,
      updateRfq: true,
      productId: this.rfqForm.value.productId,
      vendorId: this.currentUser._id,
      quantity: this.rfqForm.value.qty,
      price: this.rfqForm.value.price,
      message: this.rfqForm.value.message,
      shippingCost: this.rfqForm.value.shippingCost,
      isVendor: this.appConfig.type === 'vendor',
      currencyData: {
        from: {
          currency: product.currency || 'USD',
          rate: fromCurrency.rate
        },
        to: {
          currency: toCurrency.currencyCode || 'USD',
          rate: toCurrency.rate
        }
      },
    }
    this.vendorBuyerStore.dispatch(StartNegotiation({body}));
    this.toastr.success(this.translationService.instant('offer_resent'));
    this.modalRef?.hide();
    const message = {
      action: "NEW_MESSAGE",
      payload: {
        room: this.selectedRoom._id,
        sender: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.personId._id : this.selectedRoom.userId._id,
        receiver: (this.currentUser._id !== this.selectedRoom.userId._id) ? this.selectedRoom.userId._id : this.selectedRoom.personId._id,
        message: this.currentUser.name + ' Updated the proposal',
        status: 'sent',
      }
    };
    this.socketService.sendMessage(message);
    if (!this.chatMessages[this.selectedRoom?._id]) {
      this.chatMessages[this.selectedRoom?._id] = [];
    }
    if (!this.chatMessages[this.selectedRoom?._id]['Today']) {
      this.chatMessages[this.selectedRoom?._id]['Today'] = [];
    }
    this.chatMessages[this.selectedRoom?._id]['Today'].push({
      id: (this.currentUser._id !== this.selectedRoom?.userId._id) ? this.selectedRoom.personId._id : this.selectedRoom.userId._id,
      data: (this.currentUser._id !== this.selectedRoom?.userId._id) ? this.selectedRoom.personId : this.selectedRoom.userId,
      message: this.currentUser.name + ' Updated the proposal',
      status: 'sent'
    });
    this.rfqForm.reset();
  }

  changeTab(type: string) {
    this.tabType = type;
    this.getNegotiation(type);
    this.selectedVendor = null
  }

  getNegotiation(type: string) {
    this.vendorBuyerStore.dispatch(GetNegotiationData({params: {
        id: this.negotiationId,
        isVendor: this.appConfig.type === 'vendor',
        ...(this.appConfig.type === 'user' ? {type: type } : {})
      }}));
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
    this.socketSubscription.unsubscribe();
  }

  updateShortList(data: any) {
    if (data.hasOwnProperty('status') && data.status !== 'cancelled') {
      const body: any = {
        negotiation_id: this.negotiationDetails._id,
        proposal_id: data._id,
        vendorId: data?.vendorId?._id,
        updateShortList: true,
        isShortListed: data?.isShortListed !== true
      }
      this.vendorBuyerStore.dispatch(StartNegotiation({body}));
    }
  }

  cancelRfq() {
    let body: any = {
      negotiation_id: this.negotiationDetails._id,
      proposal_id: this.selectedProposal._id,
      cancelRfq: true
    }
    this.vendorBuyerStore.dispatch(StartNegotiation({body}));
    this.toastr.success(this.translationService.instant('Offer cancel successfully'))
    this.modalRef?.hide();
  }

  cancelOffer() {
    this.rfqForm.reset();
    this.modalRef?.hide();
  }

  downloadTermSheet(proposal: any) {
    this.vendorBuyerStore.dispatch(GetRFQTermSheet({
      orderId: this.negotiationId,
      params: {type: this.appConfig.type, proposalId: proposal._id}
    }));
  }

  confirmTermSheet() {
    let body: any = {
      negotiation_id: this.negotiationDetails._id,
      proposal_id: this.selectedProposal._id,
      confirmTermSheet: true
    }
    this.vendorBuyerStore.dispatch(StartNegotiation({body}));
    this.toastr.success(this.translationService.instant('Confirm sheet successfully'))
  }

  generateOrder(proposal: any) {
    if ((this.cartItems && this.cartItems.items || []).length > 0) {
      this.toastr.warning('You can only place one order at a time.');
      return;
    }
    let cartData: any = (this.cartItems && this.cartItems.items || []).filter((data: any) => data._id === item._id)
    cartData = Object.assign({}, ...cartData)
    let item: any = proposal;
    if (item.productId.qtyAvailable !== cartData.quantity) {
      let body: any = {
        productId: item.productId._id,
        variantId: item.productId.selectedVariation || null,
        quantity: item?.quantity,
        type: 'physical',
        ...(item.selectedOptions ? {selectedOptions: item.selectedOptions } : {}),
        selectedPrice: item.price,
        price: item.price,
        shippingCost: item.shippingCost,
        takeCustomMeasurements: item.productId.sizeMe?.isEnabled,
        shippingId: this.negotiationDetails.shippingId._id,
        paymentTerms: this.negotiationDetails.paymentTerms,
        proposalId: item._id
      }
      this.buyerStore.dispatch(AddToCartItems({ body }));
      this.toastr.success('Your product has been added to cart successfully!');
      this.router.navigate(['/user', 'cart']);
    } else {
      this.toastr.warning(this.translationService.instant('stock_limit_reached'))
    }
  }

  generateOffer(proposal: any) {
    let body: any = {
      negotiation_id: this.negotiationDetails._id,
      proposal_id: proposal._id,
      isGenerateOffer: true
    }
    this.vendorBuyerStore.dispatch(StartNegotiation({body}));
  }

  withdrawProposal(proposal: any) {
    const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model'
    });
    modelRef.content.headerTitle = 'Withdraw Proposal';
    // modelRef.content.title = 'Withdraw Proposal';
    modelRef.content.headerSubTitle = 'Are you sure you want to withdraw this offer?';
    modelRef.content.confirmText = 'Withdraw';
    modelRef.content.customClass = 'big-form-details';
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        this.vendorStore.dispatch(UpdateProposal({body: {
            isWithdraw: true,
            proposal_id: proposal?._id,
            negotiation_id: proposal?.negotiationId
          }}))
      }
    })
  }

  getFileSize(size: number) {
    return size / (1024 * 1024)
  }

  async onFileSelected(event: any) {
    const {target: {files}} = event;
    const file = files ? files[0] : undefined;
    if (file) {
      if (this.getFileSize(file.size) > 4) {
        this.toastr.warning(this.translationService.instant('cannot_upload', {size: `4MB`}));
        return false;
      }
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(async data => {
        if (data) {
          this.documentFile = data;
          this.rfqForm.get('document').setValue(file);
        }
      });
      return true;
    } else {
      this.toastr.error(this.translationService.instant('video_not_allowed'));
      return false;
    }
  }

  async uploadFile() {
    let folderPath = 'rfq/'
    let file;
    this.loaderService.show(true);
    file = await this.uploadImage.uploadImageAndGetUrl({
      fileName: this.rfqForm.value.document.name,
      contentType: this.rfqForm.value.document.type,
      file: this.rfqForm.value.document,
      folderPath
    });
    this.loaderService.hide(true);

    let body: any = {
      negotiation_id: this.negotiationDetails._id,
      proposal_id: this.selectedProposal._id,
      uploadDocument: true,
      document: file
    }
    this.vendorBuyerStore.dispatch(StartNegotiation({body}));
  }
}
